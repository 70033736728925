const IconApartment = () => (
    <svg viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.44531 16.8301V46C6.44531 47.1046 7.34074 48 8.44531 48H42.7786C43.8832 48 44.7786 47.1046 44.7786 46V16.8187C44.7786 16.1787 44.4723 15.5773 43.9546 15.201L26.9594 2.84675C26.2636 2.34099 25.3224 2.33674 24.6221 2.8362L7.284 15.2018C6.75773 15.5772 6.44531 16.1837 6.44531 16.8301Z"
            stroke="currentColor"
            strokeWidth="2"
        />
        <rect
            x="12.5547"
            y="17.0556"
            width="10.7778"
            height="10.7778"
            rx="1"
            stroke="#feca01"
            strokeWidth="2"
        />
        <rect
            x="27.8906"
            y="17.0556"
            width="10.7778"
            height="10.7778"
            rx="1"
            stroke="currentColor"
            strokeWidth="2"
        />
        <rect
            x="12.5547"
            y="32.3889"
            width="10.7778"
            height="10.7778"
            rx="1"
            stroke="currentColor"
            strokeWidth="2"
        />
        <rect
            x="27.8906"
            y="32.3889"
            width="10.7778"
            height="10.7778"
            rx="1"
            stroke="currentColor"
            strokeWidth="2"
        />
    </svg>
);

export default IconApartment;
